import React, {useState,useEffect, useCallback} from 'react';
import { Tree } from "react-arborist";
import leafIcon from '../assets/leaf-icon.png';
import projectIcon from '../assets/network.png';
import folderhaveChildrenIcon from '../assets/project.png';
import openFolderhaveChildrenIcon from '../assets/open-project.png';
import folderIcon from '../assets/folder.png';
import openFoldertIcon from '../assets/open-folder.png';
import privateIcon from '../assets/padlock.png';
import editIcon from '../assets/edit.png';
import addIcon from '../assets/plus.png';
import deleteIcon from '../assets/delete.png';
import api from '../utils/api';
import Swal from 'sweetalert2';
import { BarLoader } from 'react-spinners';
import {v4 as uuid} from "uuid"; 

//Tree 컴포넌트의 handleUpdate와 handleDelete는 트리 레벨의 작업을 처리하고, 
//Node 컴포넌트의 handleSubmit과 handleDelete는 개별 노드 레벨의 작업을 처리
function Node({ node, style, dragHandle, projects, setProjects, onNodeClick }) {
  const isPrivate = node.data.isPrivate === "1"; //"1" is private and "0" is public
  const [isHovered, setIsHovered] = useState(false); // 마우스 호버 상태 관리
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(node.data.name);

  
  const handleClick = async () => {
    if (node.data.type === 'file') {
        try {
            const response = await api.get(`/api/files/${node.data.projectId}/${node.data.id}/content`);
            if (response.status === 200) {
                onNodeClick && onNodeClick({
                    id: node.data.id,
                    type: 'file',
                    content: response.data.content,
                    name: node.data.name,
                    projectId: node.data.projectId
                });
            }
        } catch (error) {
            console.error('Error fetching file content:', error);
            Swal.fire({
                title: '파일 내용 로드 실패',
                text: '파일 내용을 불러오는데 실패했습니다.',
                icon: 'error'
            });
        }
    }
  };

  const renderEditInput = () => {
      return (
          <form onSubmit={handleSubmit}>
              <input
                  type="text"
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  onBlur={() => setIsEditing(false)}
                  autoFocus
                  style={{
                      background: 'transparent',
                      color: '#fff',
                      padding: '2px 4px',
                      width: '100%'
                  }}
              />
          </form>
      );
  };

  const handleAddItem = async (type = 'file') => {
    try {
        if (!node.data.type || (node.data.type !== 'project' && node.data.type !== 'folder')) {
            console.log('Cannot add items to this node type:', node.data.type);
            return;
        }

        const tempId = uuid(); // 임시 ID 생성
        const name = type === 'folder' ? 'New Folder' : 'New File.tf';
        const parentFolderId = node.data.type === 'project' ? 'root' : node.data.id;
        const projectId = node.data.type === 'project' ? node.data.id : node.data.projectId;

        const newNode = {
            id: tempId,
            name: name,
            type: type,
            projectId: projectId,
            parentId: parentFolderId,
            children: [],
            isLeaf: type === 'file'
        };

        // 깊은 복사로 상태 업데이트
        const updatedProjects = projects.map(project => ({
            ...project,
            id: project.id || project.projectId,
            children: project.children ? project.children.map(child => ({
                ...child,
                id: child.id || `${project.id}_${child.name}`,
                children: child.children || []
            })) : []
        }));

        const addNodeToTree = (nodes) => {
            for (let n of nodes) {
                if (n.id === node.data.id) {
                    if (!n.children) n.children = [];
                    n.children.push(newNode);
                    n.isExpanded = true; // 부모 폴더 확장 상태 유지
                    return true;
                }
                if (n.children && addNodeToTree(n.children)) return true;
            }
            return false;
        };

        // API 호출
        let response;
        if (type === 'file') {
            response = await api.post(`/api/projects/${projectId}/files`, {
                fileName: name,
                parentFolderId: parentFolderId
            });
        } else {
            response = await api.post('/api/createFolder', {
                projectId: projectId,
                parentFolderId: parentFolderId,
                folderName: name
            });
        }

        if (response.status === 200) {
            // 실제 ID로 업데이트
            const finalUpdatedProjects = JSON.parse(JSON.stringify(updatedProjects));
            const updateNodeId = (nodes) => {
                for (let n of nodes) {
                    if (n.id === tempId) {
                        n.id = response.data.id;
                        return true;
                    }
                    if (n.children && updateNodeId(n.children)) return true;
                }
                return false;
            };

            addNodeToTree(updatedProjects);
            setProjects(updatedProjects);

        }
    } catch (error) {
        console.error('Error adding item:', error);
        setProjects([...projects]); // 롤백
        
        Swal.fire({
            title: '오류',
            text: '항목 추가 중 오류가 발생했습니다.',
            icon: 'error'
        });
    }
  };

  // Node 컴포넌트의 handleSubmit 수정
  const handleSubmit = async (e) => {
    e?.preventDefault();
    
    try {
      const response = await api.put(`/api/nodes/${node.data.id}/rename`, {
        newName: editName,
        type: node.data.type,
        projectId: node.data.projectId
      });

      if (response.data) {
        const updatedProjects = JSON.parse(JSON.stringify(projects));
        
        const updateNodeInTree = (nodes) => {
          for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id === node.data.id) {
              nodes[i].data.name = editName;
              return true;
            }
            if (nodes[i].children && updateNodeInTree(nodes[i].children)) {
              return true;
            }
          }
          return false;
        };

        updateNodeInTree(updatedProjects);
        setProjects(updatedProjects);
      }
    } catch (error) {
      console.error('Error renaming node:', error);
      Swal.fire({
        title: '이름 변경 실패',
        text: '이름을 변경하는 중 오류가 발생했습니다.',
        icon: 'error'
      });
    }
    
    setIsEditing(false);
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        
        try {
            const response = await api.put('/api/rename-node', {
                nodeId: node.data.id,
                type: node.data.type,
                newName: editName
            });

            if (response.status === 200) {
                const updatedProjects = JSON.parse(JSON.stringify(projects));
                const updateNodeName = (nodes) => {
                    for (let n of nodes) {
                        if (n.id === node.data.id) {
                            n.name = editName;
                            return true;
                        }
                        if (n.children && updateNodeName(n.children)) return true;
                    }
                    return false;
                };

                updateNodeName(updatedProjects);
                setProjects(updatedProjects);
                setIsEditing(false);
            }
        } catch (error) {
            console.error('Error renaming node:', error);
            Swal.fire({
                title: '이름 변경 실패',
                text: '이름을 변경하는 중 오류가 발생했습니다.',
                icon: 'error'
            });
        }
    }
  };


  // 트리 데이터 업데이트 헬퍼 함수
  const updateTreeData = (newNodeData) => {
    const updatedProjects = JSON.parse(JSON.stringify(projects));
    
    const updateNode = (nodes) => {
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id === node.id) {
                if (!nodes[i].children) nodes[i].children = [];
                nodes[i].children.push(newNodeData);
                return true;
            }
            if (nodes[i].children && updateNode(nodes[i].children)) {
                return true;
            }
        }
        return false;
    };

    updateNode(updatedProjects);
    setProjects(updatedProjects);

    // 부모 노드가 닫혀있으면 열기
    if (!node.isOpen) {
        node.toggle();
    }

    // 새 노드 편집 모드 활성화
    setTimeout(() => {
        const tree = node.tree;
        if (tree) {
            const newNode = tree.get(newNodeData.id);
            if (newNode) {
                newNode.edit();
            }
        }
    }, 100);
  };

  useEffect(() => {
      if (node.isEditing) {
          setIsEditing(true);
          setEditName(node.data.name);
      }
  }, [node.isEditing]);

  
  const getIcon = () => {
      if (node.data.type === 'file') {
          return leafIcon;
      } else if (node.data.type === 'project') {
          return projectIcon;
      } else if (node.data.type === 'folder') {
          if (node.data.children && node.data.children.length > 0) {
              return node.isOpen ? openFolderhaveChildrenIcon : folderhaveChildrenIcon;
          } else {
              return node.isOpen ? openFoldertIcon : folderIcon;
          }
      }
      return node.data.type === 'file' ? leafIcon : folderIcon;
  };

  const handleDelete = async (node) => {
    try {
        const result = await Swal.fire({
            title: '삭제 확인',
            text: '정말로 삭제하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '삭제',
            cancelButtonText: '취소'
        });

        if (result.isConfirmed) {
            const projectId = node.data.type === 'project' ? node.data.id : node.data.projectId;
            const response = await api.delete('/api/deleteNode', {
                data: {
                    projectId: projectId,
                    nodeId: node.data.id,
                    type: node.data.type,
                    parentFolderId: node.data.parentId
                }
            });
    
            if (response.status === 200) {
                const updatedProjects = JSON.parse(JSON.stringify(projects));
                const removeNode = (nodes) => {
                    for (let i = 0; i < nodes.length; i++) {
                        if (nodes[i].id === node.data.id) {
                            nodes.splice(i, 1);
                            return true;
                        }
                        if (nodes[i].children && removeNode(nodes[i].children)) {
                            return true;
                        }
                    }
                    return false;
                };
                removeNode(updatedProjects);
                setProjects(updatedProjects);
            }
                Swal.fire({
                    title: '삭제 완료',
                    text: '성공적으로 삭제되었습니다.',
                    icon: 'success',
                    timer: 1500
                });
            }
    } catch (error) {
        console.error('Delete request error:', error.response?.data || error.message);
        Swal.fire({
            title: '삭제 실패',
            text: '노드를 삭제하는 중 오류가 발생했습니다.',
            icon: 'error'
        });
    }
  };

  const truncateName = (text, maxLength = 20) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  return (
        <div className="node" 
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                paddingLeft: `${node.level * 20}px`,
                position: 'relative',
                cursor: node.isLeaf ? 'pointer' : 'default'
            }}
            ref={dragHandle}
            data-id={node.data.id}  // 노드 식별자 추가
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}      
            onClick={(e) => {
              if (!node.isEditing) { // 편집 중이 아닐 때만 노드 클릭 처리
                node.toggle();
                handleClick(node);
                if (node.isLeaf) {
                  handleClick(node);
                }
              }
            }}>
            <div style={{ 
                display: 'flex', 
                alignItems: 'center',
                flex: 1,
            }}>
              <img src={getIcon()} alt="icon" style={{ width: '16px', height: '16px'}} />
              {isEditing ? (
                renderEditInput()
              ) : (
                <span title={node.data.name}>{truncateName(node.data.name)}</span>
              )}

        {/* 액션 아이콘들을 별도의 컨테이너로 분리 */}
        {isHovered && (
                    <div style={{ marginLeft: 'auto', display: 'flex', gap: '8px' }}>
                        {(node.data.type === 'project' || node.data.type === 'folder') && (
                            <>
                                <img
                                    src={addIcon}
                                    alt="add file"
                                    style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                                    onClick={() => handleAddItem('file')}
                                />
                                <img
                                    src={folderIcon}
                                    alt="add folder"
                                    style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                                    onClick={() => handleAddItem('folder')}
                                />
                            </>
                        )}
                        <img
                            src={editIcon}
                            alt="edit"
                            style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();  // 이벤트 전파 중지
                              setIsEditing(true);
                            }}
                        />
                        <img
                            src={deleteIcon}
                            alt="delete"
                            style={{ width: '16px', height: '16px', cursor: 'pointer' }}
                            onClick={() => handleDelete(node)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

function ProjectTree({ projects, setProjects, onNodeClick, height = 800 }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      setIsLoading(!Array.isArray(projects));
  }, [projects]);

  const handleCreate = useCallback(({ parentId, type }) => {
      const data = {
          id: `temp-${Date.now()}`,
          name: type === 'folder' ? 'New Folder' : 'New File.tf',
          type: type,
          isLeaf: type !== 'folder'
      };
      return data;
  }, []);


  const handleUpdate = useCallback(async ({ id, name }) => {
    try {
        const node = findNodeById(projects, id);
        if (!node) {
            console.error('Node not found:', id);
            return;
        }

        // 임시 노드인 경우 처리하지 않음
        if (id.startsWith('temp-')) {
            return;
        }

        let response;
        if (node.type === 'file') {
            response = await api.post('/api/updateCodeFileName', {
                codeId: id,
                newName: name
            });
        } else if (node.type === 'folder') {
            response = await api.post('/api/updateFolderName', {
                folderId: id,
                newName: name
            });
        } else if (node.type === 'project') {
            response = await api.post('/api/updateProjectName', {
                projectId: id,
                newName: name
            });
        }

        if (response && response.status === 200) {
            // 트리 데이터 업데이트
            const updatedProjects = JSON.parse(JSON.stringify(projects));
            const updateNodeName = (nodes) => {
                for (let node of nodes) {
                    if (node.id === id) {
                        node.name = name;
                        return true;
                    }
                    if (node.children && updateNodeName(node.children)) {
                        return true;
                    }
                }
                return false;
            };

            
            updateNodeName(updatedProjects);
            setProjects(updatedProjects);

            Swal.fire({
                title: '이름 변경 완료',
                text: '성공적으로 이름이 변경되었습니다.',
                icon: 'success',
                timer: 1500
            });
        }
    } catch (error) {
        console.error('Error updating node name:', error);
        Swal.fire({
            title: '이름 변경 실패',
            text: '이름을 변경하는 중 오류가 발생했습니다.',
            icon: 'error'
        });
    }
  }, [projects, setProjects]);


  // 노드 찾기 헬퍼 함수
  const findNodeById = (nodes, id) => {
      for (let node of nodes) {
          if (node.id === id) {
              return node;
          }
          if (node.children) {
              const found = findNodeById(node.children, id);
              if (found) return found;
          }
      }
      return null;
  };

  const handleDelete = useCallback(({ ids }) => {
    const updatedProjects = JSON.parse(JSON.stringify(projects));
    ids.forEach(id => {
        const removeNode = (nodes) => {
            for (let i = 0; i < nodes.length; i++) {
                if (nodes[i].id === id) {
                    nodes.splice(i, 1);
                    return true;
                }
                if (nodes[i].children && removeNode(nodes[i].children)) {
                    return true;
                }
            }
            return false;
        };
        removeNode(updatedProjects);
    });
    setProjects(updatedProjects);
  }, [projects, setProjects]);

  if (isLoading) {
    return (
        <div style={{ 
            width: '100%', 
            height: '100%', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            minHeight: '200px'
        }}>
            <BarLoader color="#36D7B7" width={200} height={4} />
        </div>
    );
  }

  return (
      <Tree
          data={projects}
          defaultExpandedIds={['root']}
          openByDefault={false}
          width={300}
          height={height}
          indent={24}
          rowHeight={36}
          paddingTop={30}
          paddingBottom={10}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          renderLabel={({ node }) => (
              <span>{node.name}</span>
          )}
      >
          {(nodeProps) => (
              <Node 
                  {...nodeProps} 
                  projects={projects} 
                  setProjects={setProjects}
                  onNodeClick={onNodeClick}  // onNodeClick prop 전달
              />
          )}
      </Tree>
  );
}


export default ProjectTree;