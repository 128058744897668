import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Swal from 'sweetalert2';
import { useDropzone } from 'react-dropzone';
import { fetchAuthSession } from 'aws-amplify/auth';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

function Dropzone({ closeModal, setDataMain, setIsLoading, setCurrentProjectId, handleUploadResponse }) {
    const maxFileSize = 524288000;
    const [files, setFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
        },
        onDrop,
        maxSize: maxFileSize
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [isFocused, isDragAccept, isDragReject]);

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    const handleUpload = async () => {
        if (files.length === 0) {
            alert("먼저 파일을 선택하세요.");
            return;
        }
    
        if (isUploading) return;
    
        setIsUploading(true);
    
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
    
        const session = await fetchAuthSession();
        const token = session.tokens ? session.tokens.accessToken : null;
    
        try {
            const response = await fetch(`${API_BASE_URL}/api/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': token ? `Bearer ${token}` : '',
                    'Accept': 'application/json'
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Success:', data);

            // AiChat 컴포넌트의 handleUploadResponse 함수 호출
            if (handleUploadResponse) {
                handleUploadResponse(data);
            }

            setFiles([]);
            closeModal();
            setIsLoading(false);

            // Sweetalert 추가
            Swal.fire({
                toast: true,
                icon: 'success',
                title: '업로드가 완료되었습니다.',
                position: 'top-end',
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            console.error('Error:', error);
            alert("파일 업로드에 실패했습니다: " + error.message);
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <section className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside style={thumbsContainer}>
                {thumbs}
            </aside>
            <div className="button-container">
                <button 
                    className={`button-4 upload-button ${isUploading ? 'disabled' : ''}`} 
                    onClick={handleUpload}
                    disabled={isUploading}
                >
                    {isUploading ? '업로드 중...' : '파일 업로드'}
                </button>
                <button className="button-4 close-button" onClick={closeModal}>X</button>
            </div>
        </section>
    );
}

export default Dropzone;