import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  baseURL: API_BASE_URL,
});

// 토큰을 선택적으로 가져오는 함수
const getToken = async (tokenType) => {
  try {
    const session = await fetchAuthSession();
    if (tokenType === 'access') {
      return session.tokens?.accessToken?.toString();
    } else if (tokenType === 'id') {
      return session.tokens?.idToken?.toString();
    }
    console.log("tokenType", tokenType);
  } catch (error) {
    console.error('Error fetching auth session:', error);
  }
  return null;
};

api.interceptors.request.use(async config => {
  const tokenType = config.tokenType || 'access'; // 기본값은 accessToken
  const token = await getToken(tokenType);
  //console.log("token", token);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(response => {
  return response;
}, async error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const tokenType = originalRequest.tokenType || 'access'; // 기본값은 accessToken
    try {
      const token = await getToken(tokenType);
      if (token) {
        originalRequest.headers['Authorization'] = `Bearer ${token}`;
      }
      return api(originalRequest);
    } catch (refreshError) {
      console.error('Error refreshing token:', refreshError);
      // Redirect to login page or handle authentication failure
      // window.location.href = '/login';
    }
  }
  return Promise.reject(error);
});

export default api;